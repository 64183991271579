const keys = [
    'RouteLeave',
    'SwitchMatch',
    'AccountQuit',
    'ToggleTabber'
];
class Key{
    constructor(){
        keys.forEach(key => {
            this[key] = Symbol(key);
        });
    }
}

export default new Key();