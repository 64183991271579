import Button from './common/Button.vue';
import Popup from './common/Popup.vue';
import Tooltip from './common/Tooltip.vue';
import Table from './common/Table.vue';
import Dropdown from './common/Dropdown.vue';
import TablePopup from './common/TablePopup.vue';
import PageHead from './common/PageHead.vue';

import toast from './toast';
import prompt from './prompt';

import form from './form';

const Components = {
    Button,
    Popup,
    Tooltip,
    Table,
    Dropdown,
    TablePopup,
    PageHead
}

export default {
    install: function(Vue){
        Object.keys(Components).forEach((key)=>{
            Vue.use(Components[key]);
        })
        Vue.prototype.$toast = toast;
        Vue.prototype.$prompt = prompt;
        Vue.use(form);
    }
};