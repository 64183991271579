import Vue from "vue"
import PromptComponent from "./src/main.vue"

const PromptConstrctor = Vue.extend(PromptComponent);
let seed = 1;

const Prompt = function(options){
    let id = 'prompt_' + seed++;
    
    let instance = new PromptConstrctor({
        data: options || {}
    });
    instance.id = id;
    instance.$mount();
    document.body.appendChild(instance.$el);
    instance.visible = true;
    return new Promise(resolve=>{
      instance.onCallback = resolve;
    })
};
export default Prompt;
