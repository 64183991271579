import Vue from "vue"
import ContextmenuComponent from "./src/main.vue"

const ContextmenuConstrctor = Vue.extend(ContextmenuComponent);
let seed = 1;

const Contextmenu = function(options){
    let id = 'contextmenu_' + seed++;
    
    let instance = new ContextmenuConstrctor({
        data: options || {}
    });
    instance.id = id;
    instance.$mount();
    document.body.appendChild(instance.$el);
    instance.visible = true;
    return new Promise(resolve=>{
      instance.onCallback = resolve;
    })
};
export default Contextmenu;
