var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('span',{staticClass:"dropdown"},[_c('el-popover',_vm._b({ref:"popover",attrs:{"visible-arrow":false,"popper-class":_vm.popperClassName},model:{value:(_vm.popoverVisible),callback:function ($$v) {_vm.popoverVisible=$$v},expression:"popoverVisible"}},'el-popover',{
            placement: 'bottom',
            ..._vm.$attrs
        },false),[_c('template',{slot:"reference"},[_vm._t("default")],2),[(_vm.$slots.header)?_c('div',{staticClass:"dropdown-header",on:{"click":function($event){_vm.popoverVisible = false}}},[_vm._t("header")],2):_vm._e(),_c('div',{staticClass:"dropdown-menu_container"},[_c('ul',{staticClass:"dropdown-menu",class:_vm.menuClassName,style:({'max-height': _vm.maxHeight})},_vm._l((_vm.list),function(item,index){return _c('li',{key:index,staticClass:"dropdown-item",class:{
                            'is-disabled': item.disabled,
                            'is-active': _vm.value !== undefined && _vm.value === item[_vm.valueKey]
                        },on:{"click":function($event){return _vm.itemChange(item)}}},[_vm._t("option",function(){return [_c('span',[_vm._v(_vm._s(item[_vm.labelKey]))])]},null,{...item, '$index': index})],2)}),0)]),(_vm.$slots.footer)?_c('div',{staticClass:"dropdown-footer"},[_vm._t("footer")],2):_vm._e()]],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }