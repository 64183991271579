import tools from './tools';
let masks = [];
export default {
    showMask: function(param = {}){
        let { 
            zIndex = tools.getZIndex(),
            background = "rgba(0,0,0,0.5)"
        } = param;
        if(masks.length){
            let maskDom = document.body.querySelector('.popup__mask');
            maskDom.style.background = background;
            maskDom.style.zIndex = zIndex;
        }else{
            let maskDom = document.createElement('div');
            maskDom.className = `popup__mask`;
            maskDom.style.width = "100%";
            maskDom.style.height = "100%";
            maskDom.style.userSelect = "none";
            maskDom.style.background = background;
            maskDom.style.position = "fixed";
            maskDom.style.top = "0";
            maskDom.style.left = "0";
            maskDom.style.zIndex = zIndex;
            document.body.appendChild(maskDom);
            maskDom.classList.add('fade-in');
        }
        masks.push({ background, zIndex });
    },
    hideMask: function(){
        let maskDom = document.body.querySelector('.popup__mask');
        if(!maskDom) return;
        masks.splice(-1);
        if(masks.length > 0){
            let { background, zIndex } = masks[masks.length - 1];
            maskDom.style.background = background;
            maskDom.style.zIndex = zIndex;
        }else{
            maskDom.classList.add('fade-out');
            maskDom.style.opacity = 0;
            setTimeout(()=>{
                document.body.removeChild(maskDom);
            }, 200)
        }
    },
    hideAllMask: function(){
        let maskDom = document.body.querySelector('.popup__mask');
        if(!maskDom) return;
        maskDom.classList.add('fade-out');
        maskDom.style.opacity = 0;
        setTimeout(()=>{
            masks = [];
            try{
                document.body.removeChild(maskDom);
            }catch{}
        }, 200)
    },
    getAttrs: function(target, prefix){
        prefix = `${prefix}-`;
        let names = target.getAttributeNames().filter(name=>RegExp(prefix).test(name));
        let obj = {};
        names.forEach(name => {
            let key = name.split(prefix)[1];
            if(key) obj[key] = target.getAttribute(name);
        });
        return obj;
    }
}