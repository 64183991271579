import Vue from 'vue';
import Vuex from 'vuex';
import storage from '@/utils/storage';
import api from '@/api';

const kUserInfo = 'UserInfo-Key';//用户信息
const kLeagueInfo = 'LeagueInfo-Key';//赛事信息
const kGroupInfo = 'GroupInfo-Key';//分组信息
const kRoundsInfo = 'RoundsInfo-Key';//轮次信息
const kRoundsNo = 'RoundsNo-Key';//当前轮次
const KGroupMatchRule = 'GroupMatchRule-key';//赛事组别赛制

const setInfo = function(stateKey, storageKey){
  return function(state, info){
    state[stateKey] = info;
    if(info){
      storage.set(storageKey, info);
    }else{
      storage.remove(storageKey);
    }
  }
}

Vue.use(Vuex)
export default new Vuex.Store({
  state: {
    userInfo: storage.get(kUserInfo) || null,
    leagueInfo: storage.get(kLeagueInfo) || null,
    groupInfo: storage.get(kGroupInfo) || null,
    roundsInfo: storage.get(kRoundsInfo) || null,
    currentRoundsNo: storage.get(kRoundsNo) || 0,
    groupMatchRule: storage.get(KGroupMatchRule) || null
  },
  getters: {
    isLogin(state){
      return !!state.userInfo;
    },
    userInfo(state){
      return state.userInfo || {};
    },
    leagueInfo(state){
      return state.leagueInfo || {};
    },
    groupInfo(state){
      return state.groupInfo || {};
    },
    leagueGroupId(state, getters){
      return getters.groupInfo.GroupId;
    },
    roundsInfo(state){
      return state.roundsInfo || {};
    },
    roundsList(state){
        if(!state.roundsInfo) return [];
      let list = [];
      let { MatchRounds, RoundsNo } = state.roundsInfo;
      for(let i=0;i<MatchRounds;i++){
        let round = i + 1;
        list.push({
          label: `第${round}轮`,
          value: round,
          disabled: round > RoundsNo
        })
      }
      return list;
    },
    roundsNo(state, getters){
      return state.currentRoundsNo || getters.roundsInfo.RoundsNo || 0;
    },
    roundsStatus(state, getters){
        if(!state.roundsInfo) return 2;
      let { RoundsNo, RoundsStatus } = state.roundsInfo;
      return (getters.roundsNo == RoundsNo) ? RoundsStatus : 2;
    },
    isCurrentRound(state, getters){
      let { RoundsNo } = getters.roundsInfo;
      return getters.roundsNo == RoundsNo;
    },
    groupMatchRule(state){
      return state.groupMatchRule;
    },
    isLeagueEnd(state, getters){
        let { MatchRounds, RoundsNo, GroupShowStatus } = getters.roundsInfo;
        return RoundsNo == MatchRounds && GroupShowStatus == 2;
    },
    isMP(state){
        return state.groupMatchRule && state.groupMatchRule.record_points_way == 1;
    }
  },
  mutations: {
    setUserInfo: setInfo('userInfo', kUserInfo),
    setLeagueInfo: setInfo('leagueInfo', kLeagueInfo),
    setGroupInfo: setInfo('groupInfo', kGroupInfo),
    setRoundsInfo: setInfo('roundsInfo', kRoundsInfo),
    setCurrentRoundsNo: setInfo('currentRoundsNo', kRoundsNo),
    setGroupMatchRule: setInfo('groupMatchRule', KGroupMatchRule)
  },
  actions: {
    clearUser(context){
      context.commit('setUserInfo', null);
      context.commit('setLeagueInfo', null);
      context.commit('setGroupInfo', null);
      context.commit('setRoundsInfo', null);
      context.commit('setCurrentRoundsNo', null);
      context.commit('setGroupMatchRule', null);
    },
    getRoundsInfo(context){
      return new Promise((resolve)=>{
        let roundsNo = context.getters.roundsNo;
        api.GetGroupRoundsStatus({
            RoundsNo: roundsNo
        }).then(res=>{
          if(res.code == 0){
            let {
              Code,
              Message,
              Timestamp,
              ...params
            } = res.info;
            let info = { ...params };
            context.commit('setRoundsInfo', info);
            if(!roundsNo){
                context.commit('setCurrentRoundsNo', params.RoundsNo);
            }
            resolve(info);
          }
        })
      })
    },
    getGroupMatchRule(context){
      return new Promise(resolve=>{
        api.GetLeagueGroupMatchRule().then(res=>{
          if(res.code == 0){
            context.commit('setGroupMatchRule', res.info.RuleConfig);
            resolve(res.info)
          }
        })
      })
    },
    getLeagueGroupInfo(context){
      return new Promise(resolve=>{
        api.GetDefaultLeagueGroup().then(res=>{
          if(res.code == 0){
            context.commit('setLeagueInfo', res.info.DefaultLeague);
            context.commit('setGroupInfo', res.info.DefaultLeagueGroup);
            resolve(res.info)
          }
        })
      })
    }
  },
  modules: {
  }
})
