var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"select",style:({'width': _vm.width})},[_c('el-popover',_vm._b({ref:"popover",attrs:{"visible-arrow":false,"popper-class":_vm.popperClassName,"disabled":_vm.disabled},model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},'el-popover',{
            trigger: 'click',
            placement: 'bottom'
        },false),[_c('template',{slot:"reference"},[_c('div',{staticClass:"select_wrapper",class:{
                'select-header': _vm.visible,
                'is-disabled': _vm.disabled
            }},[_c('input',{staticClass:"input-value",attrs:{"type":"text","readonly":"readonly","autocomplete":"off","placeholder":_vm.placeholder || '请选择'},domProps:{"value":_vm.labelText}})])]),[_c('div',{ref:"selectHeader",staticClass:"select_wrapper select-header",class:{'is-disabled': _vm.disabled},on:{"click":function($event){_vm.visible = false}}},[_c('input',{staticClass:"input-value",attrs:{"type":"text","readonly":"readonly","autocomplete":"off","placeholder":_vm.placeholder || '请选择'},domProps:{"value":_vm.labelText}})]),_c('div',{staticClass:"option-menu_container"},[_c('div',{staticClass:"option-menu"},_vm._l((_vm.options),function(item,index){return _c('div',{key:index,staticClass:"select-option",class:{
                            'is-disabled': item.disabled,
                            'is-active': _vm.val !== undefined && _vm.val === item[_vm.valueKey]
                        },on:{"click":function($event){return _vm.optionClick(item, index)}}},[_vm._t("option",function(){return [_c('span',[_vm._v(_vm._s(item[_vm.labelKey]))])]},null,{...item, '$index': index})],2)}),0)])]],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }