import operate from '@/common/operate';
import drag from '@/utils/drag';
var isDraggable = true;
var config = {};
const getParentEl = function(el, target){
    console.log(target)
    if(target)var parentNode = document.querySelector(target);
    return parentNode || el.parentNode;
}
const handle = function(el){
    let elLeft = 0,
        elTop = 0;
    let minX = config.left,
        minY = config.top,
        maxX = 0,
        maxY = 0;
    return drag({
        el: config.touchDom,
        cursor: 'move',
        start(){
            elLeft = el.offsetLeft;
            elTop = el.offsetTop;
            maxX = config.parentNode.clientWidth - el.offsetWidth - config.right;
            maxY = config.parentNode.clientHeight - el.offsetHeight - config.bottom;
        },
        move({offsetX, offsetY}){
            let x = elLeft + offsetX,
                y = elTop + offsetY;
            if (x < minX) {
                x = minX;
            } else if (x > maxX) {
                x = maxX;
            }

            if (y < minY) {
                y = minY;
            } else if (y > maxY) {
                y = maxY;
            }

            el.style.left = x + 'px';
            el.style.top = y + 'px';
        }
    })
}
let dragCallback;
export default {
    inserted(el, binding) {
        let { 
            touch,
            target,
            offset = 0,
            left = +offset,
            top = +offset,
            right = +offset,
            bottom = +offset
        } = operate.getAttrs(el, 'drag');
        let parentNode = getParentEl(el, target);
        let touchDom = el;
        if(touch){
            touchDom = el.querySelector(touch) || el;
        }
        config = {
            touchDom,
            parentNode,
            left: +left,
            top: +top,
            right: +right,
            bottom: +bottom
        }
        if(typeof binding.value == 'boolean'){
            isDraggable = binding.value;
        }else{
            isDraggable = true;
        }
        if(isDraggable){
            dragCallback = handle(el);
        }else{
            dragCallback && dragCallback.close();
        }
    },
    componentUpdated(el, binding) {
        if(typeof binding.value == 'boolean'){
            isDraggable = binding.value;
        }else{
            isDraggable = true;
        }
        if(isDraggable){
            dragCallback = handle(el);
        }else{
            dragCallback && dragCallback.close();
        }
    }
}