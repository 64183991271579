import Toast from '@/components/toast';
export default function rewriteElFormItem(el) {
    el.props = {
        ...el.props,
        showMessage: {
            type: Boolean,
            default: false
        },
        showToast: {
            type: Boolean,
            default: true
        }
    }
    el.methods = {
        ...el.methods,
        oldValidate: el.methods.validate,
        validate(trigger, callback){
            this.oldValidate(trigger, (validateMessage, invalidFields)=>{
                callback && callback(validateMessage, invalidFields);
                if(this.showToast && this.validateState == 'error'){
                    Toast.error(validateMessage);
                }
            })
        }
    }
}