<template>
    <span class="tooltip">
        <span ref="reference">
            <slot></slot>
        </span>
        <div class="tooltip__wrapper" ref="tooltip" v-show="false">
            <slot name="tooltip"></slot>
        </div>
    </span>
</template>
<script>
import createComponent from '../utils/createComponent';
import { on, off} from '@/utils/dom';
import tools from '@/common/tools';
export default createComponent({
    name: 'Tooltip',
    data(){
        return {
            timer: null,
            referenceDom: null,
            tooltip: null,
            tooltipContent: null
        }
    },
    mounted() {
        this.referenceDom = this.$refs.reference;
        this.tooltipContent = this.$refs.tooltip.innerHTML;
        this.$refs.tooltip.parentNode.removeChild(this.$refs.tooltip);
        this.init();
    },
    watch: {
      tooltipContent: {
        immediate: true,
        handler(newVal){
            if(!this.referenceDom) return;
            if(newVal){
                this.referenceDom.classList.add('is-mark');
            }else{
                this.referenceDom.classList.remove('is-mark');
            }
        }
      }  
    },
    methods: {
        init(){
            let el = this.referenceDom;
            el.classList.add('tooltip__reference-wrapper');
            off(el, 'mouseover', this.targetOver);
            off(el, 'mouseout', this.targetOut);
            on(el, 'mouseover', this.targetOver);
            on(el, 'mouseout', this.targetOut);
        },
        targetOver(e){
            clearTimeout(this.timer);
            if(this.tooltip || !this.tooltipContent)return;
            let zIndex = tools.getZIndex();
            let dom = document.createElement('div');
            dom.className = 'tooltip__wrapper';
            dom.id = 'tooltip__wrapper-' + zIndex;
            dom.innerHTML = this.tooltipContent;
            document.body.appendChild(dom);
            let width = dom.offsetWidth,
                height = dom.offsetHeight;
            let cWidth = document.body.clientWidth,
                cHeight = document.body.clientHeight;
            let pageX = e.pageX + 3,
                pageY = e.pageY + 3;
            let left = pageX,
                top = pageY;
            if(cWidth - width < pageX){
                left = cWidth - width;
            }
            if(cHeight - height < pageY){
                top = cHeight - height;
            }
            dom.style.left = left + 'px';
            dom.style.top = top + 'px';
            dom.style.zIndex = zIndex;
            // dom.onmouseover = ()=>{
            //     clearTimeout(this.timer);
            // }
            // dom.onmouseout = this.targetOut;
            this.tooltip = dom;
        },
        targetOut(){
            this.timer = setTimeout(()=>{
                this.tooltip && document.body.removeChild(this.tooltip);
                this.tooltip = null;
            }, 100)
        }
    }
})
</script>
<style lang="scss">
    .tooltip{
        display: inline-block;
    }
    .tooltip__reference-wrapper.is-mark{
        // cursor: pointer;
        &::after{
            content: '*';
            color: #F24949;
        }
    }
    .tooltip__wrapper{
        background: #FFFFFF;
        box-shadow: 0px 2px 8px -2px rgba(136,136,136,0.25);
        border-radius: 5px;
        padding: 5px 8px;
        position: absolute;
        pointer-events: none;
    }
</style>