import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routes';
import store from '@/store';
import operate from '@/common/operate';
Vue.use(VueRouter)

// 解决ElementUI导航栏中的vue-router在3.0版本以上重复点菜单报错问题
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const router = new VueRouter({
  mode: process.env.VUE_APP_ROUTER_MODE,
  base: process.env.BASE_URL,
  routes
})
router.beforeEach((to, from, next) => {
  if(from.path != to.path){
    operate.hideAllMask();
    let login = typeof to.meta.login == 'boolean' ? to.meta.login : true;
    if(to.path == '/login'){
      if(!store.getters.isLogin){
        next();
      }
    }else if(login && !store.getters.isLogin){
      next(`/login?toPath=${encodeURIComponent(to.fullPath)}`);
    }else{
      next();
    }
  }
});

export default router
