
class Precondition{
	constructor(){
		this.conditionGather = {};
	}
    run(key, callback){
        let promise;
        if(callback == undefined){
            promise = new Promise(resolve=>{
                callback = resolve;
            })
        }
        let conditions = this.conditionGather[key];
        if(conditions && conditions.length > 0){
            let n = 0;
            for (const condition of conditions) {
                condition(pass=>{
                    if(pass !== false){
                        n++;
                    }
                    if(n == conditions.length){
                        setTimeout(() => {
                            callback();
                        }, 20);
                    }
                }, key);
            }
        }else{
            setTimeout(() => {
                callback();
            }, 20);
        }
        return promise;
    }
    add(key, handler){
        if(typeof handler !== 'function') return;
        let keys;
        if(typeof key == 'string'){
            keys = [key];
        }else if(Array.isArray(key)){
            keys = [...key]
        }
        if(keys){
            for (const key of keys) {
                if(!key) continue;
                let conditions = this.conditionGather[key] || [];
                if(!conditions.includes(handler)){
                    conditions.push(handler);
                    this.conditionGather[key] = conditions;
                }
            }
        }
    }
}
const precondition = new Precondition();
export default precondition;