import axios from 'axios';
import store from '@/store';
import router from '@/router';
import tools from '@/common/tools';

import Toast from '@/components/toast';

// 创建axios实例
const service = axios.create({
  baseURL: process.env.VUE_APP_API_URL, // api 的 base_url
  timeout: 15000, // 请求超时时间
  headers:{
    "Content-Type":"application/json; charset=utf-8",
    "Accept-Language":"zh-CN"
  }
})
export default class Request {
    http(param){
		var url = param.url,
			method = param.method || "POST",
			data = param.data || {},
			showLoading = param.showLoading || false, //为true显示loading...提示，可填写要显示的文字
			noErrorCode = param.code || false; //不作为失败的code的列表，true表示所有code都不作为失败
        var loadingInstance;
        if(showLoading){
            let text = typeof showLoading == 'string' ? showLoading : '';
            loadingInstance = Toast.loading(text)
        }
        let requestStr = JSON.stringify(data);
        let localRequestTimestamp1 = tools.secondTimestamp();
        return new Promise((resolve)=>{
            service({
                url,
                method,
                data: requestStr
            }).then(res=>{
                if(showLoading) loadingInstance.close();

                let data = res.data;
                if(data){
                    if(data.Timestamp){
                        let localRequestTimestamp2 = tools.secondTimestamp();
                        let serverResponeTimstamp = data.Timestamp;
                        tools.setTimeSkew(localRequestTimestamp1, localRequestTimestamp2, serverResponeTimstamp);
                    }
                    let code = data.Code;
                    if (code === 0 || noErrorCode === true || (noErrorCode && noErrorCode.length && noErrorCode.includes(code))){
                        resolve({
                            code: 0,
                            info: data
                        });
                    }else if([9611, 9612, 9621, 9622, 9623, 9624, 9625, 9626, 9627, 9628, 9629, 9111].includes(code)){
                        store.dispatch('clearUser');
                        router.push('/login');
                    }else {
                        resolve({
                            code: code,
                            info: data.Message
                        });
                    }
                }else{
                    resolve({
                        code: -2,
                        info: "接口异常，请稍后重试。"
                    })
                }
            }).catch(err=>{
                if(showLoading) loadingInstance.close();
                resolve({
                    code: -1,
                    info: "网络异常，请稍后重试。"
                })
            })
        })
    }

    // API请求，自动显示业务错误
    httpWithError(param){
        return new Promise((resolve)=>{
			this.http(param).then(res=>{
				if(res.code !== 0) {
                    let duration = res.info.length * 200;
                    duration = duration < 3000 ? 3000 : duration;
                    Toast.error(res.info, duration);
				}
                resolve(res);
			});
		})
    }
}


 
