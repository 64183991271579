<template>
    <div class="contextmenu_wrapper" :style="{'z-index': zIndex}">
        <ul>
            <li v-for="(item,index) in list" 
                :class="{
                    'border': item.border,
                    'is-disabled': item.disabled
                }" :key="index" 
                @click="menuClick(item)">
                <span>{{item.label}}</span>
            </li>
        </ul>
    </div>
</template>
<script>
import { on, off} from '@/utils/dom';
export default {
    name: 'contextmenu',
    data(){
        return {
            visible: false,
            list: [],
            pageX: 0,
            pageY: 0,
            zIndex: 0
        }
    },
    watch: {
        visible(newVal){
            if(newVal){
                this.$nextTick(this.init);
                this.zIndex = this.$tools.getZIndex();
            }else{
                this.close()
            }
        }
    },
    methods: {
        init(){
            let offset = 3;
            let wrapper = this.$el;
            let width = wrapper.offsetWidth,
                height = wrapper.offsetHeight;
            let cWidth = document.body.clientWidth,
                cHeight = document.body.clientHeight;
            let left = this.pageX + offset, 
                top = this.pageY + offset;
            if(width + this.pageX > cWidth){
                left = this.pageX - width - offset;
            }
            if(height + this.pageY > cHeight){
                top = this.pageY - height - offset;
            }
            wrapper.style.left = left + 'px';
            wrapper.style.top = top + 'px';
            wrapper.classList.add('in');
            setTimeout(()=>{
                wrapper.classList.remove('in');
            }, 500)
            setTimeout(()=>{
                on(document, 'click', this.close);
                on(document, 'contextmenu', this.close);
            }, 50)
        },
        close(){
            off(document, 'click', this.close);
            off(document, 'contextmenu', this.close);

            this.$el.classList.add('out');
            setTimeout(()=>{
                this.$destroy(true);
                this.$el.parentNode.removeChild(this.$el);
            }, 500)
        },
        menuClick(obj){
            if(obj.disabled) return;
            this.close();
            if(this.onCallback) this.onCallback(obj);
        }
    }
}
</script>
<style lang="scss" scoped>
.contextmenu_wrapper{
    width: auto;
    height: auto;
    padding: 6px 0;
    background: #FFFFFF;
    box-shadow: 0px 2px 8px -2px rgba(136,136,136,0.25);
    border-radius: 5px;
    position: absolute;
    &.in{
        @include fade-in;
    }
    &.out{
        @include fade-out;
    }
    ul{
        width: auto;
        height: auto;
        li{
            height: 30px;
            min-width: 84px;
            padding: 0 16px;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            &:hover{
                background: #F2F5F6;
            }
            &.border{
                position: relative;
                &::after{
                    content: '';
                    width: calc(100% - 32px);
                    border-bottom: 1px solid #EAEDF2;
                    position: absolute;
                    left: 16px;
                    bottom: -5px;
                }
                & + li{
                    margin-top: 9px;
                }
            }
            &.is-disabled{
                cursor: not-allowed;
                span{
                    color: #9FA3AE;
                }
                &:hover{
                    background: none
                }
            }
            span{
                @include text-style-default;
            }
        }
    }
}
</style>