import { on, off } from './dom';
let dragConfig = {},
    param = {};
const mousedown = function (e) {
    if(typeof dragConfig.start == 'function' && dragConfig.start() == false) return false;
    document.body.style.cursor = dragConfig.cursor;
    param.startX = e.pageX;
    param.startY = e.pageY;

    on(document, 'mousemove', mousemove, true);
    on(document, 'mouseup', mouseup, true);
}
const mousemove = function(e) {
    param.endX = e.pageX;
    param.endY = e.pageY;
    param.offsetX = param.endX - param.startX;
    param.offsetY = param.endY - param.startY;
    if(typeof dragConfig.move == 'function') dragConfig.move(param);
}
const mouseup = function (e) {
    document.body.style.cursor = '';
    off(document, 'mousemove', mousemove, true);
    off(document, 'mouseup', mouseup, true);
    if(typeof dragConfig.end == 'function') dragConfig.end(param);
}
const drag = function (params){
    if(!params.el) return;
    params.el.style.cursor = params.cursor;
    on(params.el, 'mousedown', function(e){
        dragConfig = params;
        mousedown(e);
    }, true);
    return {
        close: function(){
            if(!params.el)return;
            params.el.style.cursor = '';
            off(params.el, 'mousedown', mousedown, true);
        }
    }
}
export default drag;