export default {
    set: function(key, data){
        sessionStorage.setItem(key, JSON.stringify({ value: data }));
    },
    get: function(key){
        let data = JSON.parse(sessionStorage.getItem(key) || null);
        if(data){
            return data.value;
        }
    },
    remove: function(key){
        sessionStorage.removeItem(key);
    }
}