import Request from './request';

const request = new Request();

export default {
    //后台扫码登录－准备
    LoginQrCodePrepare: function (showLoading) {
        return request.httpWithError({
            url: "/NewApi/Account/LoginQrCodePrepare",
            data: {
                ManagerUserType: 12
            },
            showLoading: showLoading || '二维码获取中'
        });
    },
    //后台扫码登录－查询
    LoginQrCodeQuery: function (param) {
        return request.httpWithError({
            url: "/NewApi/Account/LoginQrCodeQuery",
            data: param
        });
    },
    //退出登录
    Logout: function () {

    },
    /*******************common */
    //获取省级行政区域列表
    GetProvinceList: function (param) {
        return request.httpWithError({
            url: "/NewApi/Common/GetProvinceList",
            data: param
        });
    },
    //获取市级行政区域列表
    GetCityList: function (param) {
        return request.httpWithError({
            url: "/NewApi/Common/GetCityList",
            data: param
        });
    },
    //获取区县级行政区域列表
    GetAreaList: function (param) {
        return request.httpWithError({
            url: "/NewApi/Common/GetAreaList",
            data: param
        });
    },
    /************** 赛事分组轮次 **/
    //获取赛事组别轮次状态信息
    GetGroupRoundsStatus: function (param) {
        return request.httpWithError({
            url: "/NewApi/GuanDanMatch/GetGroupRoundsStatus",
            showLoading: true,
            data: param
        });
    },
    //获取赛事组别赛制设置
    GetGroupMatchRule: function (param) {
        return request.httpWithError({
            url: "/NewApi/GuanDanMatch/GetGroupMatchRule",
            data: param
        });
    },
    //设置赛事组别完赛
    ConfirmGroupComplete: function (param) {
        return request.httpWithError({
            url: "/NewApi/GuanDanMatch/ConfirmGroupComplete",
            data: param
        });
    },
    /*************对阵 */
    //自动编排检查对阵
    CheckMatchTableTempsForAutoArrange: function (param) {
        return request.httpWithError({
            url: "/NewApi/GuanDanMatch/CheckMatchTableTempsForAutoArrange",
            data: param,
            showLoading: '正在检查对阵'
        });
    },
    //自动编排生成对阵表
    AutoArrangeMatchTable: function (param) {
        return request.httpWithError({
            url: "/NewApi/GuanDanMatch/AutoArrangeMatchTable",
            data: param,
            showLoading: '正在生成对阵表'
        });
    },
    //获取对阵表
    GetMatchTable: function (param) {
        return request.httpWithError({
            url: "/NewApi/GuanDanMatch/GetMatchTable",
            data: param,
            showLoading: '对阵表获取中'
        });
    },
    //获取对阵表单行
    GetMatchTableOne: function (param) {
        return request.httpWithError({
            url: "/NewApi/GuanDanMatch/GetMatchTableOne",
            data: param,
            showLoading: '列表信息更新中'
        });
    },
    //发布对阵表
    PublicMatchTable: function (param) {
        return request.httpWithError({
            url: "/NewApi/GuanDanMatch/PublicMatchTable",
            data: param,
            showLoading: '对阵表发布中'
        });
    },
    //查询队伍 对阵编辑
    GetGroupTeamsForMatchTable: function (param) {
        return request.httpWithError({
            url: "/NewApi/GuanDanMatch/GetGroupTeamsForMatchTable",
            data: param
        });
    },
    //获取下一桌号
    GetNextTableNo: function (param) {
        return request.httpWithError({
            url: "/NewApi/GuanDanMatch/GetNextTableNo",
            data: param,
        });
    },
    //获取对阵修改后关联的对阵信息
    GetMatchTableEditRelationMatchTables: function (param) {
        return request.httpWithError({
            url: "/NewApi/GuanDanMatch/GetMatchTableEditRelationMatchTables",
            data: param,
            showLoading: '对阵信息获取中'
        });
    },
    //保存对阵
    SaveMatchTableTemp: function (param) {
        return request.httpWithError({
            url: "/NewApi/GuanDanMatch/SaveMatchTableTemp",
            data: param,
            showLoading: '对阵信息保存中'
        });
    },
    //取消对阵
    CancelMatchTableTemp: function (param) {
        return request.httpWithError({
            url: "/NewApi/GuanDanMatch/CancelMatchTableTemp",
            data: param,
            showLoading: '对阵取消中'
        });
    },
    //删除对阵
    DelMatchTableTemp: function (param) {
        return request.httpWithError({
            url: "/NewApi/GuanDanMatch/DelMatchTableTemp",
            data: param,
            showLoading: '对阵删除中'
        });
    },
    //恢复对阵
    RestoreMatchTableTemp: function (param) {
        return request.httpWithError({
            url: "/NewApi/GuanDanMatch/RestoreMatchTableTemp",
            data: param,
            showLoading: '对阵恢复中'
        });
    },
    //撤回已发布的对阵表
    WithdrawPublishedMatchTable: function (param) {
        return request.httpWithError({
            url: "/NewApi/GuanDanMatch/WithdrawPublishedMatchTable",
            data: param,
            showLoading: '正在撤销已发布的对阵表'
        });
    },
    //还原回已发布的对阵表
    RestorePublishedMatchTable: function (param) {
        return request.httpWithError({
            url: "/NewApi/GuanDanMatch/RestorePublishedMatchTable",
            data: param,
            showLoading: '对阵列表还原中'
        });
    },
    /**************成绩 */
    //查询轮次成绩表
    GetRoundsPoints: function (param) {
        return request.httpWithError({
            url: "/NewApi/GuanDanMatch/GetRoundsPoints",
            data: param,
            showLoading: '成绩表获取中'
        });
    },
    //保存轮次成绩
    SaveRoundsPoints: function (param) {
        return request.httpWithError({
            url: "/NewApi/GuanDanMatch/SaveRoundsPoints",
            data: param,
            showLoading: '成绩保存中'
        });
    },
    //清空某桌成绩
    ClearRoundsMatchTablePoints: function (param) {
        return request.httpWithError({
            url: "/NewApi/GuanDanMatch/ClearRoundsMatchTablePoints",
            data: param,
            showLoading: '正在清空成绩'
        });
    },
    //获取某轮次某桌成绩
    GetRoundsMatchTablePoints: function (param, showLoading) {
        return request.httpWithError({
            url: "/NewApi/GuanDanMatch/GetRoundsMatchTablePoints",
            data: param,
            showLoading: showLoading || '列表成绩更新中'
        });
    },
    //获取轮次对阵扣分记录
    GetRoundsMatchTablePointsDeducts: function (param, showLoading) {
        return request.httpWithError({
            url: "/NewApi/GuanDanMatch/GetRoundsMatchTablePointsDeducts",
            data: param,
            showLoading: showLoading || '记录获取中'
        });
    },
    //保存扣分记录
    SavePointsDeduct: function (param) {
        return request.httpWithError({
            url: "/NewApi/GuanDanMatch/SavePointsDeduct",
            data: param,
            showLoading: '记录保存中'
        });
    },
    //删除扣分记录
    DelPointsDeduct: function (param) {
        return request.httpWithError({
            url: "/NewApi/GuanDanMatch/DelPointsDeduct",
            data: param,
            showLoading: '正在删除'
        });
    },
    //计算某桌成绩
    CountTablePoints: function (param) {
        return request.httpWithError({
            url: "/NewApi/GuanDanMatch/CountTablePoints",
            data: param,
            showLoading: '成绩计算中'
        });
    },
    //获取某桌记分记录
    GetTableDeckPoints: function (param) {
        return request.httpWithError({
            url: "/NewApi/GuanDanMatchRecordPoints/GetTableDeckPoints",
            data: param,
            showLoading: '记分记录获取中'
        });
    },
    //保存某桌某副牌结果
    SaveTableDeckResult: function (param) {
        return request.httpWithError({
            url: "/NewApi/GuanDanMatchRecordPoints/SaveTableDeckResult",
            data: param,
            showLoading: '结果保存中'
        });
    },
    //清除某桌某副牌结果
    ClearTableDeckResult: function (param) {
        return request.httpWithError({
            url: "/NewApi/GuanDanMatchRecordPoints/ClearTableDeckResult",
            data: param,
            showLoading: '结果清除中'
        });
    },

    //上报某桌成绩
    SubmitTableDecksResult: function (param) {
        return request.httpWithError({
            url: "/NewApi/GuanDanMatchRecordPoints/SubmitTableDecksResult",
            data: param,
            showLoading: '成绩上报中'
        });
    },
    /**************mp（比赛分） */
    //获取Mp记分轮次成绩表
    GetMpRoundsPoints: function (param) {
        return request.httpWithError({
            url: "/NewApi/GuanDanMatchMp/GetMpRoundsPoints",
            data: param,
            showLoading: '成绩表获取中'
        });
    },
    //某桌记分记录
    GetMpTableDeckPoints: function (param) {
        return request.httpWithError({
            url: "/NewApi/GuanDanMatchMp/GetMpTableDeckPoints",
            data: param,
            showLoading: '记分记录获取中'
        });
    },
    //保存某副牌结果
    SaveMpTableDeckResult: function (param) {
        return request.httpWithError({
            url: "/NewApi/GuanDanMatchMp/SaveMpTableDeckResult",
            data: param,
            showLoading: '结果保存中'
        });
    },

    //清空某副牌结果
    ClearMpTableDeckResult: function (param) {
        return request.httpWithError({
            url: "/NewApi/GuanDanMatchMp/ClearMpTableDeckResult",
            data: param,
            showLoading: '结果清除中'
        });
    },
    //结束本轮该桌比赛
    EndMpTableMatch: function (param) {
        return request.httpWithError({
            url: "/NewApi/GuanDanMatchMp/EndMpTableMatch",
            data: param,
            showLoading: '成绩上报中'
        });
    },
    //重置上报状态
    ResetMpSubmitStatus: function (param) {
        return request.httpWithError({
            url: "/NewApi/GuanDanMatchMp/ResetMpSubmitStatus",
            data: param,
            showLoading: '结束状态重置中'
        });
    },

    //保存 某桌成绩 - 弃权、缺席、轮空
    SaveMpTableSpecialRoundsPoints: function (param) {
        return request.httpWithError({
            url: "/NewApi/GuanDanMatchMp/SaveMpTableSpecialRoundsPoints",
            data: param,
            showLoading: '成绩保存中'
        });
    },
    //结算本轮
    ConfirmMpRoundsPoints: function (param) {
        return request.httpWithError({
            url: "/NewApi/GuanDanMatchMp/ConfirmMpRoundsPoints",
            data: param,
            showLoading: '本轮结算中'
        });
    },
    //获取轮次mp计算列表
    GetRoundsMpCountList: function (param) {
        return request.httpWithError({
            url: "/NewApi/GuanDanMatchMp/GetRoundsMpCountList",
            data: param,
            showLoading: '列表获取中'
        });
    },
    //获取轮次某副牌mp计算信息
    GetRoundsMpCountOne: function (param, showLoading) {
        return request.httpWithError({
            url: "/NewApi/GuanDanMatchMp/GetRoundsMpCountOne",
            data: param,
            showLoading
        });
    },

    //计算mp分
    CountRoundsDecksMpPoints: function (param) {
        return request.httpWithError({
            url: "/NewApi/GuanDanMatchMp/CountRoundsDecksMpPoints",
            data: param,
            showLoading: 'MP计算中'
        });
    },
    //MP%查询
    GetGroupTeamsMpDetails: function (param) {
        return request.httpWithError({
            url: "/NewApi/GuanDanMatchMp/GetGroupTeamsMpDetails",
            data: param,
            showLoading: '列表获取中'
        });
    },
    //MP%查询 导出
    ExportGroupTeamsMpDetails: function (param) {
        return request.httpWithError({
            url: "/NewApi/GuanDanMatchMp/ExportGroupTeamsMpDetails",
            data: param,
            showLoading: '正在导出MP%表'
        });
    },

    /**************积分 */
    //确认本轮成绩并生成积分表
    ConfirmRoundsPoints: function (param) {
        return request.httpWithError({
            url: "/NewApi/GuanDanMatch/ConfirmRoundsPoints",
            data: param,
            showLoading: '正在生成本轮积分表'
        });
    },
    //获取积分表
    GetGroupPoints: function (param) {
        return request.httpWithError({
            url: "/NewApi/GuanDanMatch/GetGroupPoints",
            data: param,
            showLoading: '积分表获取中'
        });
    },
    //重新生成积分表
    RemakeGroupPoints: function (param) {
        return request.httpWithError({
            url: "/NewApi/GuanDanMatch/RemakeGroupPoints",
            data: param,
            showLoading: '积分表生成中'
        });
    },
    //积分表导出
    ExportGroupPoints: function (param) {
        return request.httpWithError({
            url: "/NewApi/GuanDanMatch/ExportGroupPoints",
            data: param,
            showLoading: '正在导出积分表'
        });
    },
    /***************队伍 */
    //获取队伍列表
    GetGroupTeams: function (param, showLoading) {
        return request.httpWithError({
            url: "/NewApi/GuanDanMatch/GetGroupTeams",
            data: param,
            showLoading: showLoading || '列表获取中'
        });
    },
    //获取队伍列表一行
    GetGroupTeamsOne: function (param) {
        return request.httpWithError({
            url: "/NewApi/GuanDanMatch/GetGroupTeamsOne",
            data: param,
            showLoading: '获取选手信息'
        });
    },
    //队伍签到
    GroupTeamSignin: function (param) {
        return request.httpWithError({
            url: "/NewApi/GuanDanMatch/GroupTeamSignin",
            data: param,
            showLoading: '选手签到中'
        });
    },
    //撤销签到
    GroupTeamCancelSignin: function (param) {
        return request.httpWithError({
            url: "/NewApi/GuanDanMatch/GroupTeamCancelSignin",
            data: param,
            showLoading: '选手签到撤销中'
        });
    },
    //队伍全部签到
    GroupTeamsSigninAll: function (param) {
        return request.httpWithError({
            url: "/NewApi/GuanDanMatch/GroupTeamsSigninAll",
            data: param,
            showLoading: '全部选手签到中'
        });
    },
    //队伍全部撤销签到
    GroupTeamsCancelSigninAll: function (param) {
        return request.httpWithError({
            url: "/NewApi/GuanDanMatch/GroupTeamsCancelSigninAll",
            data: param,
            showLoading: '全部选手签到撤销中'
        });
    },
    //获取队伍当前比赛
    GetGroupTeamNowMatch: function (param) {
        return request.httpWithError({
            url: "/NewApi/GuanDanMatch/GetGroupTeamNowMatch",
            data: param,
            showLoading: '选手信息获取中'
        });
    },
    //队伍退赛
    GroupTeamExitMatch: function (param) {
        return request.httpWithError({
            url: "/NewApi/GuanDanMatch/GroupTeamExitMatch",
            data: param,
            showLoading: '退赛信息保存中'
        });
    },
    //查询队伍退赛信息
    GetGroupTeamExitInfo: function (param) {
        return request.httpWithError({
            url: "/NewApi/GuanDanMatch/GetGroupTeamExitInfo",
            data: param,
            showLoading: '退赛信息获取中'
        });
    },
    //更新退伍退赛信息
    UpdateGroupTeamExitInfo: function (param) {
        return request.httpWithError({
            url: "/NewApi/GuanDanMatch/UpdateGroupTeamExitInfo",
            data: param,
            showLoading: '退赛信息保存中'
        });
    },
    //队伍重新参赛
    GroupTeamRejoinMatch: function (param) {
        return request.httpWithError({
            url: "/NewApi/GuanDanMatch/GroupTeamRejoinMatch",
            data: param,
            showLoading: '保存中'
        });
    },
    //新建队伍
    CreateGroupTeam: function (param) {
        return request.httpWithError({
            url: "/NewApi/GuanDanMatch/CreateGroupTeam",
            data: param,
            code: [20103],
            showLoading: '选手信息保存中'
        });
    },
    //修改队伍
    AlterGroupTeam: function (param) {
        return request.httpWithError({
            url: "/NewApi/GuanDanMatch/AlterGroupTeam",
            data: param,
            code: [20103],
            showLoading: '选手信息修改中'
        });
    },
    //检查队伍比赛数据
    CheckGroupTeamJoinData: function (param) {
        return request.httpWithError({
            url: "/NewApi/GuanDanMatch/CheckGroupTeamJoinData",
            data: param
        });
    },
    //删除队伍
    DelGroupTeam: function (param) {
        return request.httpWithError({
            url: "/NewApi/GuanDanMatch/DelGroupTeam",
            data: param,
            showLoading: '选手删除中'
        });
    },
    //获取下一个队伍编号
    GetNextTeamNo: function (param) {
        return request.httpWithError({
            url: "/NewApi/GuanDanMatch/GetNextTeamNo",
            data: param
        });
    },
    //修改单位名称
    AlterUnitName: function (param) {
        return request.httpWithError({
            url: "/NewApi/GuanDanMatch/AlterUnitName",
            data: param,
            showLoading: '代表队名称修改中'
        });
    },
    //导出现有选手
    ExportNowGroupTeams: function (param) {
        return request.httpWithError({
            url: "/NewApi/GuanDanMatch/ExportNowGroupTeams",
            data: param,
            showLoading: '现有选手导出中'
        });
    },
    //导入组别选手
    ImportGroupTeams: function (param) {
        return request.httpWithError({
            url: "/NewApi/GuanDanMatch/ImportGroupTeams",
            data: param,
            code: [20104],
            showLoading: '组别选手导入中'
        });
    },
    //获取当前组别现有编号列表
    GetNowGroupTeamNos: function (param) {
        return request.httpWithError({
            url: "/NewApi/GuanDanMatch/GetNowGroupTeamNos",
            data: param,
            showLoading: '获取现有编号'
        });
    },
    /****************投屏 */
    //开启投屏
    SetProjectionOpen: function (param) {
        return request.httpWithError({
            url: "/NewApi/GuanDanMatch/SetProjectionOpen",
            data: param,
            showLoading: '正在投屏'
        });
    },
    //关闭投屏
    SetProjectionClosed: function (param) {
        return request.httpWithError({
            url: "/NewApi/GuanDanMatch/SetProjectionClosed",
            data: param,
            showLoading: '投屏关闭中'
        });
    },
    //创建投屏token
    CreateProjectionToken: function (param) {
        return request.httpWithError({
            url: "/NewApi/GuanDanMatch/CreateProjectionToken",
            data: param
        });
    },
    /*********************赛事 */
    //获取默认赛事分组
    GetDefaultLeagueGroup: function (param) {
        return request.httpWithError({
            url: "/NewApi/GuanDanOrganize/GetDefaultLeagueGroup",
            data: param,
            showLoading: '分组获取中'
        });
    },
    //设置默认赛事分组
    SetDefaultLeagueGroup: function (param) {
        return request.httpWithError({
            url: "/NewApi/GuanDanOrganize/SetDefaultLeagueGroup",
            data: param,
            showLoading: true
        });
    },
    //获取我的赛事列表
    GetMyLeagueList: function (param) {
        return request.httpWithError({
            url: "/NewApi/GuanDanOrganize/GetMyLeagueList",
            data: param,
            showLoading: '赛事列表获取中'
        });
    },
    //获取我的赛事列表一行
    GetMyLeagueListOne: function (param) {
        return request.httpWithError({
            url: "/NewApi/GuanDanOrganize/GetMyLeagueListOne",
            data: param,
            showLoading: true
        });
    },
    //获取我的赛事详情
    GetMyLeague: function (param) {
        return request.httpWithError({
            url: "/NewApi/GuanDanMatch/GetMyLeague",
            data: param,
            showLoading: '获取赛事信息'
        });
    },
    //保存赛事
    SaveMyLeague: function (param) {
        return request.httpWithError({
            url: "/NewApi/GuanDanMatch/SaveMyLeague",
            data: param,
            showLoading: '保存中'
        });
    },
    //获取赛制
    GetLeagueGroupMatchRule: function (param) {
        return request.httpWithError({
            url: "/NewApi/GuanDanMatch/GetLeagueGroupMatchRule",
            data: param,
            showLoading: '获取赛制'
        });
    },
    //保存赛制
    SaveLeagueGroupMatchRule: function (param) {
        return request.httpWithError({
            url: "/NewApi/GuanDanMatch/SaveLeagueGroupMatchRule",
            data: param,
            showLoading: '保存中'
        });
    },
    //确认完赛
    ConfirmLeagueComplete: function (param) {
        return request.httpWithError({
            url: "/NewApi/GuanDanMatch/ConfirmLeagueComplete",
            data: param,
            showLoading: true
        });
    },
    /*********************赛事数据 */

    //对阵查询队伍列表
    GetGroupTeamsForStats: function (param) {
        return request.httpWithError({
            url: "/NewApi/GuanDanMatch/GetGroupTeamsForStats",
            data: param,
            showLoading: '对阵数据获取中'
        });
    },
    //获取队伍轮次成绩列表
    GetGroupTeamRoundsScores: function (param) {
        return request.httpWithError({
            url: "/NewApi/GuanDanMatch/GetGroupTeamRoundsScores",
            data: param,
            showLoading: '选手轮次详情获取中'
        });
    },
    //获取组别 成绩总表、总积分表
    GetGroupTotalScores: function (param, loading) {
        return request.httpWithError({
            url: "/NewApi/GuanDanMatch/GetGroupTotalScores",
            data: param,
            showLoading: loading
        });
    },
    //导出 成绩总表
    ExportGroupTotalScores: function (param) {
        return request.httpWithError({
            url: "/NewApi/GuanDanMatch/ExportGroupTotalScores",
            data: param,
            showLoading: "正在导出成绩总表"
        });
    },
    //导出 总积分表
    ExportGroupTotalScores1: function (param) {
        return request.httpWithError({
            url: "/NewApi/GuanDanMatch/ExportGroupTotalScores1",
            data: param,
            showLoading: "正在导出总积分表"
        });
    },

}