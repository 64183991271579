import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import keys from './common/keys';
import tools from './common/tools';
import operate from './common/operate';
import api from './api';
import connection from './common/connection';
import precondition from './common/precondition';

import Components from './components';
import ElementUI from 'element-ui';
import rewriteElPopover from './utils/rewriteElPopover';
import rewriteElFormItem from './utils/rewriteElFormItem';

rewriteElPopover(ElementUI.Popover);
rewriteElFormItem(ElementUI.FormItem);
Vue.use(ElementUI, { size: 'mini', zIndex: 2000 });

Vue.use(Components);

import "./assets/scss/style.scss";
import "./assets/scss/element.scss";

import directives from './directives';
Vue.use(directives);

Vue.prototype.$key = keys;
Vue.prototype.$tools = tools;
Vue.prototype.$operate = operate;
Vue.prototype.$api = api;
Vue.prototype.$connection = connection;
Vue.prototype.$precondition = precondition;
Vue.prototype.$currentPage = function(){
  try{
    console.log(this.$route.name, this);
    return this.$route.name.toLowerCase() == this.name.toLowerCase();
  }catch{
    return false;
  }
}

Vue.config.productionTip = false;
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
