<template>
    <button 
        type="button"
        class="button"
        :class="buttonClass"
        @click="handleClick"
    >
        <img :src="iconSrc" class="button-icon is-left" v-if="iconSrc && iconPosition == 'left'">
        <span>
            <slot></slot>
        </span>
        <img :src="iconSrc" class="button-icon is-right" v-if="iconSrc && iconPosition == 'right'">
    </button>
</template>
<script>
import createComponent from '../utils/createComponent';
export default createComponent({
    name: 'Button',
    props: {
        type:{
            type: String,
            default: 'default',
            validator: v=>['default', 'primary', 'danger', 'gradient', 'text'].includes(v)
        },
        disabled: Boolean,
        noDisabledEvents: Boolean,//不禁用事件的触发
        plain: Boolean,
        border: Boolean,
        round: {
            type: Boolean,
            default: true
        },
        selected: Boolean,
        icon: String,
        iconPosition: {
            type: String,
            default: 'left',
            validator: v=>['left', 'right'].includes(v)
        }
    },
    data(){
        return {

        }
    },
    computed: {
        buttonClass(){
            return {
                [`is-${this.type}`]: true,
                'is-disabled': this.disabled,
                'is-plain': this.plain,
                'is-border': this.border,
                'is-round': this.round,
                'is-selected': this.selected
            }
        },
        iconSrc(){
            let src;
            if(this.icon){
                try{
                    src = require(`@icon/${this.icon}.png`);
                }catch{
                    try{
                        src = require(`@icon/${this.icon}@2x.png`);
                    }catch{}
                }
            }
            return src;
        }
    },
    methods: {
        handleClick(e){
            if(!this.disabled || this.noDisabledEvents){
                this.$emit('click', e);
            }
        }
    }
})
</script>
<style lang="scss" scoped>
    //类型、plain样式
    @mixin default($bg, $color: #FFFFFF){
        background: $bg;
        color: $color;
    }
    //border样式
    @mixin border($bc, $color: $bc){
        background: none;
        border: 1px solid $bc;
        color: $color;
        box-sizing: border-box;
    }
    .button{
        padding: 7px 17px;
        box-sizing: border-box;
        margin-right: 12px;
        border: none;
        border-radius: 5px;
        overflow: hidden;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        font-family: PingFangSC-Regular;
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
        &:last-of-type{
            margin-right: 0;
        }
        &.is-disabled{
            filter: opacity(0.6);
            cursor: not-allowed;
        }
        &.is-round{
            border-radius: 100px;
        }
        &.is-default{
            @include border(#C3C3C3, #293647);
        }
        &.is-primary{
            @include default(#3078FF);
            &.is-plain{
                @include default(#F0F6FF, #3078FF);
            }
            &.is-border{
                @include border(#3078FF);
            }
        }
        &.is-danger{
            @include default(#F24949);
            // &.is-plain{
            // }
            &.is-border{
                @include border(#F24949);
            }
        }
        &.is-gradient{
            width: 154px;
            height: 44px;
            @include default(linear-gradient(270deg, #3BA2FF 0%, #2544FF 100%));
            font-family: PingFangSC-Medium;
            font-size: 17px;
            &.is-disabled{
                filter: none;
                @include default(linear-gradient( 270deg, #E4E8ED 0%, #ADB1B7 100%));
            }
        }
        &.is-text{
            padding: 0 5px;
            @include default(none);
            border-radius: 0;
            font-size: 13px;
            color: #3078FF;
            line-height: 20px;
            font-weight: 500;
            .button-icon{
                &.is-left{
                    margin-left: 0;
                    margin-right: 3px;
                }
                &.is-right{
                    margin-left: 3px;
                    margin-right: 0;
                }
            }
        }
        
        &.is-selected{
            @include border(#3078FF);

            background-color: #3078FF1A;
            position: relative;
            &::after{
                content: '';
                width: 24px;
                height: 24px;
                position: absolute;
                bottom: 0;
                right: 0;
                background: url('~@icon/button_selected.png') no-repeat center / 100% 100%;
            }
        }
        .button-icon{
            width: 20px;
            height: 20px;
            vertical-align: middle;
            &.is-left{
                margin-left: -6px;
                margin-right: 4px;
            }
            &.is-right{
                margin-right: -6px;
                margin-left: 4px;
            }
        }
    }
</style>