export default function rewriteElPopover(el) {
    el.props = {
        ...el.props,
        overflow: Boolean // 为el-popover追加名为'overflow'的props属性
    }
    el.methods = {
        ...el.methods,
        // 重写el-popover的方法
        doShow() {
            // 如果设置了overflow，则判断文字是否溢出，若未溢出则不显示popover
            if (this.overflow) {
                let reference = this.$el.querySelector('.el-popover__reference')
                if (reference.scrollWidth <= reference.clientWidth) return;
            }
            // el-popover自带的，保留
            this.showPopper = true;
        },
        handleMouseEnter() {
            clearTimeout(this._timer);
            if (this.openDelay) {
              this._timer = setTimeout(() => {
                this.doShow();//修改为调用doShow
              }, this.openDelay);
            } else {
                this.doShow();//修改为调用doShow
            }
          }
    }
}