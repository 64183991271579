<template>
    <div class="input" :class="{
            'is-disabled': disabled
        }" 
        :style="{'width': width}"
    >
        <input 
            ref="input"
            class="input-value" :class="alignClass"
            v-bind="$attrs"
            :type="type"
            :disabled="disabled"
            :readonly="readonly"
            autocomplete="off"
            @input="handleInput"
            @focus="handleFocus"
            @blur="handleBlur"
            @change="handleChange"
        >
    </div>
</template>
<script>
import createComponent from '../../utils/createComponent';
export default createComponent({
    name: 'Input',
    props: {
        value: String | Number,
        disabled: Boolean,
        readonly: Boolean,
        type: {
            type: String,
            default: 'text'
        },
        align: String,
        width: String
    },
    data(){
        return {
        }
    },
    mounted() {
      this.setNativeInputValue();
    },
    computed: {
        alignClass(){
            return `is-${this.align || 'left'}`
        },
        nativeInputValue() {
            return this.value === null || this.value === undefined ? '' : String(this.value);
        },
    },
    watch: {
        nativeInputValue() {
            this.setNativeInputValue();
        },
    },
    methods: {
        focus() {
            this.getInput().focus();
        },
        blur() {
            this.getInput().blur();
        },
        setNativeInputValue() {
            const input = this.getInput();
            if (!input) return;
            if (input.value === this.nativeInputValue) return;
            input.value = this.nativeInputValue.trim();
        },
        handleInput(event){
            if (event.target.value === this.nativeInputValue) return;

            this.$emit('input', event.target.value.trim());
            this.$nextTick(this.setNativeInputValue);
        },
        handleChange(event){
            this.$emit('change', event.target.value.trim());
        },
        handleBlur(event){
            this.$emit('blur', event);
        },
        handleFocus(event){
            this.$emit('focus', event);
        },
        getInput() {
            return this.$refs.input;
        },
    }
})
</script>
<style lang="scss" scoped>
    .input{
        display: inline-block;
        width: 100%;
        height: 34px;
        position: relative;
        vertical-align: middle;
        &.is-disabled{
            filter: opacity(0.6);
            .input-value{
                cursor: not-allowed;
            }
        } 
        .input-value{
            width: 100%;
            height: 100%;
            padding: 8px;
            box-sizing: border-box;
            background-color: #F7FAFC;
            border-radius: 5px;
            @include text-style-default;
            color: #293647;
            @include ellipsis;
            &::-webkit-input-placeholder {
                color: #9C9EA1;
                text-overflow: clip;
            }
            &::-webkit-outer-spin-button,
            &::-webkit-inner-spin-button {
                -webkit-appearance: none;
            }
            &[type="number"]{
                -moz-appearance: textfield;
            }
            &.is-left{
                text-align: left;
            }
            &.is-center{
                text-align: center;
            }
            &.is-right{
                text-align: right;
            }
        }
    }
    
    .el-form-item.is-error{
        .input .input-value{
            // background-color: #ffd9d9;
        }
    }
</style>