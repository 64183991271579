import Vue from 'vue';
import Tooltip from '@/components/common/Tooltip.vue';

const instances = new WeakMap();

export default {
    inserted(el, binding){
        let TooltipConstrctor = Vue.extend(Tooltip);
        let instance = new TooltipConstrctor({
            data: {
                referenceDom: el,
                tooltipContent: binding.value,
            }
        })
        instance.init();
        instances.set(el, instance);
    },
    update(el, binding){
        let instance = instances.get(el);
        instance.tooltipContent = binding.value;
    },
    unbind(el) {
        try{
            instances.remove(el);
        }catch{}
    }
}