<template>
    <div class="container table">
        <el-table
            ref="table"
            :data="tableData"
            v-bind="{
                height: '100%',
                'highlight-current-row': true,
                ...$attrs
            }"
            :header-cell-class-name="headerCellClassName"
            :row-class-name="rowClassName"
            v-on="$listeners"
            @row-click="handleRowClick"
            @row-contextmenu="handleRowContextmenu"
        >
            <el-table-column v-for="column,index in columns" :key="`${column.prop}_${index}`" v-bind="{
                'align': 'center',
                ...column
            }">
                <!--自定义表头的内容-->
                <template #header>
                    <slot :name="`${column.prop}_header`" v-bind="column">
                        <div class="table_sort" v-if="column.sortable">
                            <span>{{column.label}}</span>
                            <i class="sort-icon"></i>
                        </div>
                        <template v-else>{{column.label}}</template>
                    </slot>
                </template>
                <!-- 自定义列的内容 -->
                <!-- 包含子项 -->
                <template #default v-if="column.children">
                    <el-table-column v-for="chilColumn,chilIndex in column.children" :key="`${column.prop}_${chilColumn.prop}_${chilIndex}`" v-bind="{
                        'align': 'center',
                        ...chilColumn
                    }">
                        <!--自定义表头的内容-->
                        <template #header>
                            <slot :name="`${chilColumn.prop}_header`" v-bind="column">{{chilColumn.label}}</slot>
                        </template>
                        <!-- 自定义列的内容 -->
                        <template #default="scope">
                            <slot :name="chilColumn.prop" v-bind="scope">
                                {{scope.row[chilColumn.prop] ?? (chilColumn.null != undefined ? chilColumn.null : '-')}}
                            </slot>
                        </template>
                    </el-table-column>
                </template>
                <!-- end -->
                <template #default="scope" v-else>
                    <slot :name="column.prop" v-bind="scope">
                        {{scope.row[column.prop] ?? (column.null != undefined ? column.null : '-')}}
                    </slot>
                </template>
            </el-table-column>
            <!-- 插入至表格最后一行之后的内容-->
            <template #append v-if="$slots.append">
                <slot name="append" />
            </template>
            <!-- 空数据时显示的内容 -->
            <template #empty v-if="$slots.empty">
                <slot name="empty" />
            </template>
        </el-table>
    </div>
</template>
<script>
import createComponent from '../utils/createComponent';
import contextmenu from '../contextmenu';
export default createComponent({
    name: "Table",
    props: {
        data: Array,
        columns: Array,
        stripe: {
            type: Boolean,
            default: true
        }
    },
    data(){
        return {
            currentRow: null,
            currentColumn: null
        }
    },
    computed: {
        tableData(){
            let data = this.data;
            return data;
        }  
    },
    methods: {
        headerCellClassName({row, rowIndex, column, columnIndex}){
            let className = `${column.property}_header ${this.handleClassName('header-cell-class-name', {row, rowIndex, column, columnIndex})}`;
            let columnConfig,
                len = row.length;
            if(rowIndex == 0){
                columnConfig = this.columns.find(item=>item.prop == column.property);
            }else if(rowIndex == 1){
                this.columns.forEach(columnItem => {
                    if(columnItem.children){
                        columnItem.children.forEach(chilColumnItem=>{
                            if(chilColumnItem.prop == column.property){
                                columnConfig = chilColumnItem;
                                return;
                            }
                        })
                        if(columnConfig) return;
                    }
                });
            }
            if(columnConfig && columnConfig.border == true){
                if(columnIndex != len - 1){
                    className += ' border';
                }
            }
            return className;
        },
        rowClassName({ row, rowIndex, ...params }){
            let className = this.handleClassName('row-class-name', {row, rowIndex, ...params});
            if(this.stripe && rowIndex % 2 == 0){
                className += ' striped-row'
            }
            return className;
        },
        handleClassName(attrName, params){
            let className = '';
            let classNameAttr = this.$attrs[attrName];
            if(classNameAttr){
                if(typeof classNameAttr == 'string'){
                    className += classNameAttr;
                }else if(typeof classNameAttr == 'function'){
                    className += classNameAttr(params) || '';
                }
            }
            return className;
        },
        handleRowClick(row, ...arg){
            if(this.currentRow != row){
                this.currentRow = row;
                this.$emit('current-change', this.currentRow);
            }
            this.$emit('row-click', row, ...arg);
        },
        handleRowContextmenu(row, column, event){
            let columnConfig = this.columns.find(item=>item.prop == column.property);
            if(columnConfig && columnConfig.contextmenu){
                let list = [];
                if(typeof columnConfig.contextmenu == 'function'){
                    list = columnConfig.contextmenu.call(this.$parent, row, column);
                }else{
                    list = columnConfig.contextmenu;
                }
                if(!list || list.length == 0) return;
                contextmenu({
                    list,
                    pageX: event.pageX,
                    pageY: event.pageY
                }).then(obj=>{
                    if(obj.handler) obj.handler.call(this.$parent, row, column);
                })
                event.preventDefault();
            }
            this.$emit('row-contextmenu', row, column, event);
            return false;
        },
        doLayout(){
            this.$refs.table.doLayout();
        }
    }
})
</script>
<style lang="scss" scoped>
    .table{
        border: 1px solid #EAEDF2;
        box-sizing: border-box;
        .el-table::v-deep{
            @include text-style-default;
            line-height: 20px;

            &::before,
            &::after{
                content: none;
            }
            thead{
                color: inherit;
                &.is-group tr{
                    th.el-table__cell{
                        height: 43px;
                        background: transparent;
                    }
                    &:last-of-type{
                        th.el-table__cell{
                            height: 30px;
                            padding-bottom: 11px;
                            &.border::after{
                                height: 16px;
                                top: calc(50% - 14px);
                            }
                        }
                    }
                } 
            }
            td.el-table__cell,
            th.el-table__cell{
                height: 50px;
                border: none;
                padding: 0 5px;
                box-sizing: border-box;
                .cell{
                    padding: 0;
                    overflow: initial;
                }
            }
            th.el-table__cell{
                font-family: PingFangSC-Medium;
                font-size: 14px;
                font-weight: 500;
                &.is-leaf{
                    border: none;
                }
                &.border{
                    position: relative;
                    &::after{
                        content: "";
                        width: 0;
                        height: 26px;
                        border-left: 1px solid #EAEDF2;
                        position: absolute;
                        top: calc(50% - 13px);
                        right: 0;
                    }
                }
            }
            .el-table__row{
                &.striped-row{
                    background-color: #F7FAFC;
                }
                &.error{
                    background: rgba(242,73,73,0.1);
                }
            }
            .el-table__body-wrapper{
                font-size: inherit;
                .el-table__empty-block{
                    background: #F7FAFC;
                    font-size: 16px;
                    .el-table__empty-text{
                        color: inherit;
                    }
                }
            }
            .el-table__row.error{
                background-color: rgba(242,73,73,0.1);
            }
            .el-table__body tr.el-table__row.current-row,
            .el-table__body tr.el-table__row:hover,
            .el-table__body tr.hover-row{
                background-color: #DFEAFF;
            }
            .el-table__body tr.el-table__row > td.el-table__cell,
            .el-table__body tr.hover-row > td.el-table__cell{
                background: none !important;
            }
            .el-table__body tr.el-table__row td.el-table__cell:hover{
                background: #D5E8F5 !important;
            }
            .is-sortable{
                &.descending .table_sort .sort-icon{
                    background-image: url('~@icon/table_sort_descending.png');
                }
                &.ascending .table_sort .sort-icon{
                    background-image: url('~@icon/table_sort_ascending.png');
                }
                .caret-wrapper{
                    display: none;
                }
                .table_sort{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    line-height: 26px;
                    .sort-icon{
                        width: 16px;
                        height: 16px;
                        margin-left: 6px;
                        background: url('~@icon/table_sort.png') no-repeat center / 100% 100%;
                    }
                }
            }
            .el-table__expand-column{
                display: none;
            }
            td.el-table__cell.el-table__expanded-cell{
                padding: 0;
            }
        }
    }
</style>