<template>
    <transition name="toast" @after-leave="handleAfterLeave">
        <div class="toast__wrapper" :class="`is-${type}`" v-if="visible" :style="wrapperStyle">
             <div class="toast-icon" v-if="type">
                <img :src="require(`@icon/toast_${type}@2x.png`)">
             </div>
             <div class="toast-message" v-if="message">{{message}}</div>
        </div>
    </transition>
</template>
<script>

export default {
    name: 'toast',
    data(){
        return {
            visible: false,
            type: '',
            message: '',
            duration: 3000,
            zIndex: 0
        }
    },
    mounted(){
        this.startTimer();
    },
    watch:{
        visible(newVal){
            if(newVal){
                this.zIndex = this.$tools.getZIndex();
            }
        }
    },
    computed: {
        wrapperStyle(){
            return {
                zIndex: this.zIndex,
                top: this.top + 'px'
            }
        }
    },
    methods: {
        handleAfterLeave() {
            this.$destroy(true);
            this.$el.parentNode.removeChild(this.$el);
            if (typeof this.onAfterLeave === 'function') {
                this.onAfterLeave(this);
            }

        },
        close(){
            if (typeof this.onClose === 'function') {
                this.onClose(this);
            }
        },
        startTimer() {
            if (this.type !== 'loading') {
                setTimeout(() => {
                    this.close();
                }, this.duration || 3000);
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.toast-enter-active {
    animation: in 0.3s;
}
.toast-leave-active {
    animation: out 0.3s;
}
@keyframes in{
    0%{
        opacity: 0;
        transform: translate(-50%, -64px);
    }
    100%{
        opacity: 1;
        transform: translate(-50%, 0px);
    }
}
@keyframes out{
    0%{
        opacity: 1;
        transform: translate(-50%, 0);
    }
    100%{
        opacity: 0;
        transform: translate(-50%, -64px);
    }
}
@keyframes move{
    0%{
        transform: translate(-50%, 64px);
    }
    100%{
        transform: translate(-50%, 0px);
    }
}
.toast__wrapper{
    width: auto;
    height: 54px;
    padding: 0 13px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #FFFFFF;
    box-shadow: 0px 21px 28px -5px rgba(136,136,136,0.25), 0px -5px 23px 2px rgba(136,136,136,0.1);
    border-radius: 30px;
    position: absolute;
    top: 76px;
    left: calc(50% + 120px);
    transform: translateX(-50%);
    &.move{
        animation: move 0.3s;
    }
    &.is-loading{
        .toast-icon img{
            animation: loading 1.5s linear infinite;
        }
    }
    .toast-icon{
        width: 34px;
        height: 34px;
        margin-right: 1px;
        img{
            width: 100%;
            height: 100%;
        }
    }
    .toast-message{
        padding: 0 15px;
        font-family: PingFangSC-Regular;
        font-size: 16px;
        color: #46556B;
        font-weight: 400;
    }
}
@keyframes loading {
    from {
        transform: rotate(0);
    }
    to {
        transform: rotate(360deg);
    }
}
</style>