const signalR = require("@microsoft/signalr");
import Toast from '@/components/toast';
import Prompt from '@/components/prompt';
let isPrompt = false;
const showPrompt = function(){
	if(isPrompt) return;
	isPrompt = true;
	Prompt({
		title: '网络异常',
		message: '连接已断开。请稍后重试',
		showCancel: false,
		confirmText: '重试',
	}).then(()=>{
		isPrompt = false;
		newConnection.start();
	})
}
class Methods {
	constructor(){
		this.methods = [];
	}
	add(method){
		if(method instanceof Function){
			this.methods.push(method);
		}
	}
	run(...arg){
		console.log('run', ...arg)
		if(this.methods.length){
			this.methods.forEach(method=>{
				method && method(...arg);
			})
		}
	}
	clear(){
		this.methods = [];
	}
}
class Connection {
	constructor(){
		this.reconnectNum = 0;//重连次数
		this.maxReconnect = 10;//最大重连次数
		this.initMethods();
		this.build();
	}
	initMethods(){
		this.readyMethod = new Methods();
		this.connectedMethod = new Methods();
		this.closeMethod = new Methods();
		this.connectStateChangeMethod = new Methods();
	}
	build(){
		let _this = this;
		this.connection = new signalR.HubConnectionBuilder()
		.withAutomaticReconnect({
			nextRetryDelayInMilliseconds(retryContext){
				console.log('retry', retryContext);
				if (this.doNotConnect || retryContext.previousRetryCount > _this.maxReconnect){
					console.log('ws', 'do not retry');
					return null;
				}
				return 5000;
			}
		})
		.withUrl(process.env.VUE_APP_WS_URL, {
			skipNegotiation: true,
			transport: 1
		})
		.build();
		let loading;
		this.connection.onreconnecting(()=>{
			loading && loading.close();
			loading = Toast.loading('网络断开重连...');
			this.state = 'Connecting';
			this.connectStateChangeMethod.run(this.state);
		});
		this.connection.onreconnected(()=>{
			loading && loading.close();
			this.state = 'Connected';
			this.connectedMethod.run();
			this.connectStateChangeMethod.run(this.state);
			Toast.success('网络重连成功');
		});
		this.connection.onclose(()=>{
			loading && loading.close();
			console.log('ws', 'onclose');
			if (this.doNotConnect){
				console.log('ws', 'doNotConnect')
			} else {
				//如果连接关闭
				this.state = 'Disconnected';
				this.closeMethod.run();
				this.connectStateChangeMethod.run(this.state);
				showPrompt();
			}
		})
	}
	start(){
		this.doNotConnect = false; //要连接
		if(!this.connection){
			this.build();
		}
		if(this.isConnected()) return;
		
		let loading = Toast.loading('网络连接中...');
		this.state = 'Connecting';
		this.connectStateChangeMethod.run(this.state);
		this.connection.start().then(()=>{
			clearTimeout(this.timeout);
			this.reconnectNum = 0;
			loading.close();
			Toast.success('网络连接成功');
			this.state = 'Connected';

			this.connectedMethod.run();
			this.readyMethod.run();
			this.readyMethod.clear();
			this.connectStateChangeMethod.run(this.state);
		}).catch((e)=>{
			console.log('ws', 'start error', this.connection._connectionState, e);
			clearTimeout(this.timeout);
			loading.close();
			if(this.reconnectNum >= this.maxReconnect){
				this.reconnectNum = 0;
				this.state = 'Disconnected';
				this.connectStateChangeMethod.run(this.state);
				showPrompt();
			}else{
				//如果连接关闭，自动重新连接
				this.timeout = setTimeout(()=>{
					console.log('ws', 'call start');
					this.start();
					this.reconnectNum++;
				}, 2000);
			}
		})
	}
	ready(method){
		if(this.isConnected()){
			method && method();
		}else{
			this.readyMethod.add(method);
			if(this.state != 'Connecting'){
				this.start();
			}
		}
	}
	on(name, method){
		this.connection.on(name, method);
	}
	invoke(name, ...arg){
		this.ready(()=>{
			this.connection.invoke(name, ...arg);
		})
	}
	off(name, method){
		this.connection.off(name, method);
	}
	stop(){
		console.log('ws', 'stop...');
		this.doNotConnect = true; //不要连接
		clearTimeout(this.timeout);
		this.startNum = undefined;
		this.connection.stop(); //stop()方法也是Promise，可以写回调的。
	}
	isConnected(){
		return this.connection && this.connection._connectionState == 'Connected';
	}
	onconnectStateChange(method){
		this.connectStateChangeMethod.add(method);
	}
	onconnected(method){
		this.connectedMethod.add(method);
	}
	onclose(method){
		this.closeMethod.add(method);
	}
}
const newConnection = new Connection();
export default newConnection;