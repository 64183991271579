import Checkbox from './src/Checkbox.vue';
import Select from './src/Select.vue';
import Input from './src/Input.vue';
import Radio from './src/Radio.vue';
const Forms = {
    Checkbox,
    Select,
    Input,
    Radio
}

export default {
    install: function(Vue){
        Object.keys(Forms).forEach((key)=>{
            Vue.use(Forms[key]);
        })
    }
};