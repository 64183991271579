import debounce from './src/debounce';
import drag from './src/drag';
import tooltip from './src/tooltip';
import VueHotkey from 'v-hotkey';
const directives = {
    debounce,
    drag,
    tooltip
}

export default {
    install(Vue){
        Object.keys(directives).forEach((key)=>{
            Vue.directive(key, directives[key]);
        })
        Vue.use(VueHotkey);
    }
}