<template>
    <div class="container table">
        <el-table
            ref="table"
            :data="tableData"
            v-bind="{
                ...$attrs
            }"
            :header-cell-class-name="headerCellClassName"
            :cell-class-name="cellClassName"
            :header-cell-style="headerCellStyle"
            :cell-style="cellStyle"
        >
            <el-table-column v-for="column,index in columns" :key="`${column.prop}_${index}`" v-bind="{
                'align': 'center',
                ...column
            }">
                <!--自定义表头的内容-->
                <template #header>
                    <slot :name="`${column.prop}_header`" v-bind="column">{{column.label}}</slot>
                </template>
                <!-- 自定义列的内容 -->
                <template #default="scope">
                    <slot :name="column.prop" v-bind="scope">
                        {{scope.row[column.prop] ?? (column.null != undefined ? column.null : '-')}}
                    </slot>
                </template>
            </el-table-column>
            <!-- 插入至表格最后一行之后的内容-->
            <template #append v-if="$slots.append">
                <slot name="append" />
            </template>
            <!-- 空数据时显示的内容 -->
            <template #empty v-if="$slots.empty">
                <slot name="empty" />
            </template>
        </el-table>
    </div>
</template>
<script>
import createComponent from '../utils/createComponent';
export default createComponent({
    name: "TablePopup",
    props: {
        data: [Array, Number],
        columns: Array
    },
    data(){
        return {
        }
    },
    computed: {
        tableData(){
            let data = this.data;
            if(typeof data == 'number'){
                return new Array(data).fill({});
            }else{
                data = data.map((item,index)=>({$index: index, ...item}));
                return data;
            }
        },
        rowLen(){
            return this.tableData.length;
        },
        columnLen(){
            return this.columns.length;
        }
    },
    methods: {
        headerCellClassName({column, columnIndex, ...params}){
            let className = `${column.property}_header ${this.handleClassName('header-cell-class-name', {column, columnIndex, ...params})}`;
            let obj = this.columns[columnIndex];
            if(obj.required === true){
                className += ' is-required'
            }
            return className;
        },
        cellClassName({rowIndex, column, columnIndex, ...params}){
            let className = `${column.property} row_${rowIndex + 1} ${this.handleClassName('cell-class-name', {rowIndex, column, columnIndex, ...params})}`;
            let obj = this.columns[columnIndex];
            if(obj.border === undefined || obj.border === true){
                if(rowIndex < this.rowLen - 1){
                    className += ' bottom-border';
                }
                if(columnIndex < this.columnLen - 1){
                    className += ' right-border';
                }
            }
            return className;
        },
        handleClassName(attrName, params){
            let className = '';
            let classNameAttr = this.$attrs[attrName];
            if(classNameAttr){
                if(classNameAttr instanceof String){
                    className += classNameAttr;
                }else if(classNameAttr instanceof Function){
                    className += classNameAttr(params);
                }
            }
            return className;
        },
        headerCellStyle({columnIndex, ...params}){
            let styles = { };
            let {
                style,
                headerStyle
            } = this.columns[columnIndex];
            if(style && style instanceof Object){
                styles = { ...style };
            }
            if(headerStyle && headerStyle instanceof Object){
                styles = { ...styles, ...headerStyle };
            }
            return {
                ...styles,
                ...this.handleCellStyle('header-cell-style', {columnIndex, ...params})
            }
        },
        cellStyle({columnIndex, ...params}){
            let styles = { };
            let {
                style
            } = this.columns[columnIndex];
            if(style && style instanceof Object){
                styles = { ...style };
            }
            return {
                ...styles,
                ...this.handleCellStyle('cell-style', {columnIndex, ...params})
            }
        },
        handleCellStyle(attrName, params){
            let styles = {};
            let cellStyleAttr = this.$attrs[attrName];
            if(cellStyleAttr){
                if(cellStyleAttr instanceof Object){
                    styles = cellStyleAttr;
                }else if(cellStyleAttr instanceof Function){
                    styles = cellStyleAttr(params);
                }
            }
            return styles;
        },
    }
})
</script>
<style lang="scss" scoped>
    .table{
        padding: 12px;
        box-sizing: border-box;
        background: #F5F5F5;
        border-radius: 5px;
        .el-table::v-deep {
            background: none;
            border: none;
            @include text-style-default;
            line-height: 20px;
            &::before,
            &::after{
                content: none;
            }
            thead{
                color: inherit;
            }
            .el-table__header-wrapper{
                padding-bottom: 8px;
                box-sizing: border-box;
                tr{
                    background: none;
                }
            }
            .el-table__body-wrapper{
                background: #ffffff;
            }
            th.el-table__cell.is-leaf{
                border: none;
            }
            th.el-table__cell{
                height: 17px;
                background: none;
                padding: 0;
                border: none;
                font-family: PingFangSC-Regular;
                font-size: 12px;
                color: #747B83;
                font-weight: 400;
                line-height: 17px;
                box-sizing: border-box;
                &.is-left.Text_header{
                    padding-left: 20px;
                }
                &.is-required .cell::after{
                    content: '*';
                    color: #F24949;
                }
                .cell{
                    height: 17px;
                    padding: 0;
                    line-height: 17px;
                    overflow: initial;
                }
            }
            td.el-table__cell{
                height: 50px;
                padding: 0;
                border: none;
                box-sizing: border-box;
                background: none;
                &.is-left.Text{
                    padding-left: 20px;
                }
                &.Text{
                    color: #798087;
                }
                .cell{
                    padding: 0;
                    line-height: 18px;
                    overflow: initial;
                }
            }
            .el-table__body-wrapper{
                font-size: inherit;
                .el-table__empty-block{
                    font-size: 16px;
                    .el-table__empty-text{
                        color: inherit;
                    }
                }
            }
            .el-table__row.current-row,
            .el-table__row:hover{
                background: #ffffff;
            }
            .el-table__body tr.current-row>td.el-table__cell,
            .el-table__body tr.selection-row>td.el-table__cell,
            .el-table__body tr:hover>td.el-table__cell,
            .el-table__body tr:hover>td.el-table__cell{
                background: none;
            }
            .right-border{
                border-right: 1px solid #E9E9E9 !important;
                box-sizing: border-box;
            }
            .bottom-border{
                border-bottom: 1px solid #E9E9E9 !important;
                box-sizing: border-box;
            }
        }
    }
</style>