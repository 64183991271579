<template>
    <Popup :visible.sync="visible" destroy-on-close id="prompt_popup" width="392px" :show-close="showClose">
        <template #title>
            <div class="prompt_title">
                <img src="@icon/popup_hint.png">
                <span>{{title || '提示'}}</span>
            </div>
        </template>
        <div class="prompt_content">
            <div class="prompt_text" v-if="useHTMLString" v-html="message"></div>
            <div class="prompt_text" v-else>{{message}}</div>
            <div class="prompt_operation">
                <Button type="primary" plain @click="btnHandler(false)" v-if="showCancel">{{cancelText || '取消'}}</Button>
                <Button type="gradient" @click="btnHandler(true)">{{confirmText || '确定'}}</Button>
            </div>
        </div>
    </Popup>
</template>
<script>
export default {
    name: 'prompt',
    data(){
        return {
            visible: false,
            title: "",
            message: "",
            useHTMLString: false,
            cancelText: "",
            showCancel: true,
            confirmText: "",
            showClose: true,
            onCallback: null,
        }
    },
    methods:{
        close(){
            this.visible = false;
        },
        btnHandler(confirm){
            if (typeof this.onCallback === 'function') {
                this.onCallback(confirm);
            }
            this.close();
        }
    }

}
</script>
<style lang="scss" scoped>
    #prompt_popup::v-deep{
        .popup_header{
            height: 46px;
            padding: 0 16px;
        }
        .popup_content{
            padding: 18px 0;
            .popup_content_scroll{
                padding: 0 18px;
            }
        }
        .prompt_title{
            display: flex;
            align-items: center;
            img{
                width: 22px;
                height: 22px;
                margin-right: 6px;
            }
            span{
                font-size: 16px;
            }
        }
        .prompt_content{
            .prompt_text{
                font-family: PingFangSC-Regular;
                font-size: 13px;
                color: #46556B;
                line-height: 22px;
                font-weight: 400;
                margin-bottom: 22px;
            }
            .prompt_operation{
                height: 34px;
                display: flex;
                justify-content: end;
                .button{
                    min-width: 72px;
                    width: auto;
                    height: 34px;
                    font-size: 14px;
                }
            }
        }
    }
</style>